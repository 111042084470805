import icon_ieem from "./images/IEEM.jpg"
import icon_hka from "./images/HKA.jpg"
import "./Header.css"
import React from "react";

class Header extends React.Component
{
    render(){
        return(
            <div className="grid-header">
                <div>
                    <a href="https://www.h-ka.de/ieem/profil" rel="noreferrer" target="_blank">
                        <img src={icon_ieem} alt="IEEM-icon" className="IEEM-icon"/>
                        <img src={icon_hka} alt="HKA-icon" className="HKA-icon"/>
                    </a>
                </div>

                <h1>Automotive Security Tool for assessing the reasonable grace period for disclosing information on security vulnerabilities in vehicle software</h1>

                <p className="subtitle">For more detailed information on terms used, scores and calculation formulas, please refer to the White Paper and Specification Document. In principle, it is recommended to study the relevant documents before using this tool (for the first time).</p>
            </div>
        )
    }
}

export default Header
